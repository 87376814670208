<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formatedDate"
          dense
          :disabled="disabled"
          :outlined="outlined"
          :hide-details="hideDetails"
          :error-messages="errors"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :range="isRange"
        :allowed-dates="validateAllowedDates"
        @change="save(date)"
        scrollable
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'DatePicker',
  props: {
    allowedDates: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    isRange: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Array, Object, String],
      default: null
    }
  },
  data () {
    return {
      date: this.value,
      menu: false,
      validateAllowedDates: (val) => {
        if (this.allowedDates.length) {
          return this.allowedDates.includes(val)
        }
        return true
      }
    }
  },
  computed: {
    formatedDate: function () {
      if (this.isRange) {
        const fromFormat = (this.value && this.value.from) ? moment(this.value.from).format('DD/MM/YYYY') : ''
        const toFormat = (this.value && this.value.to) ? moment(this.value.to).format('DD/MM/YYYY') : ''
        return (this.value) ? fromFormat + ' , ' + toFormat : ''
      } else {
        return (this.value) ? moment(this.value).format('DD/MM/YYYY') : ''
      }
    }
  },
  methods: {
    save (date) {
      if (this.isRange) {
        this.$emit('input', {
          from: date[0],
          to: date[1]
        })
        this.$refs.menu.save(date)
      } else {
        this.$emit('input', date)
        this.$refs.menu.save(date)
      }
    }
  }
}
</script>
